@import "../Assets/Functions.less";
@import (inline) "../Assets/Utilities.css";
@mobile: ~"(max-width: 767px)";
@tablet: ~"(min-width: 768px)";
@tablet-only: ~"(min-width: 768px) and (max-width: 1199px)";
@desktop: ~"(min-width: 1200px)";
@mobileNavColor: #1a1a1a;
@castleDetailsColor: #a200ff;
@cloudUrl: "https://files.bookingonline.co.uk/image/upload/";
@cloudUrlTheme: "https://files.bookingonline.co.uk/image/upload/f_auto/themes/014/";
@cloudUrlThemeNoAuto: "https://files.bookingonline.co.uk/image/upload/themes/014/";

/*#region Fonts*/
.OpenSans, .BodyFont {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
}

.OpenSansSemiBold {
	font-family: 'Open Sans', sans-serif;
	font-weight: 600 !important;
}

.OpenSansBold {
	font-family: 'Open Sans', sans-serif;
	font-weight: 700 !important;
}

.Montserrat {
	font-family: 'Montserrat', sans-serif;
	font-weight: 400 !important;
}

.MontserratLight {
	font-family: 'Montserrat', sans-serif;
	font-weight: 300 !important;
}

.MontserratSemiBold {
	font-family: 'Montserrat', sans-serif;
	font-weight: 600 !important;
}

.MontserratBold {
	font-family: 'Montserrat', sans-serif;
	font-weight: 700 !important;
}

.Oswald {
	font-family: 'Oswald', sans-serif;
	font-weight: 400 !important;
}

.OswaldMed {
	font-family: 'Oswald', sans-serif;
	font-weight: 500 !important;
}

html {
	.BodyFont;
	font-size: 16px;
}

body {
	overflow-x: hidden;
	background: #000;
}

.textContent {
	font-size: 1rem;

	@media @mobile {
		text-align: center;
	}
}

.textContent h1, .lower-content h2 {
	.Oswald;
	color: #4b3ade;
	text-transform: uppercase;
	font-size: 2.5rem;
	margin-top: 0;
}

.lower-content{
	padding: 25px 20px 15px;
	background: #fff;
}

.section-title {
	&--h2 {
		.Oswald;
		color: #fff;
		font-size: 2.5rem;
	}

	&--h3 {
		font-size: 1.875rem;
	}

	&--h4 {
	}
}
/*#endregion Fonts*/
#BodyContent {
	@media @mobile {
		padding-top: 0;
	}
}

.nicefont, h1, h2, h3 {
}

form {
	margin: 0 auto;
	position: relative;
}

.outer-wrapper {
	max-width: 100vw;
	position: relative;

	@media @mobile {
		overflow: hidden;
	}

	&.outer-wrapper--alt {
		position: relative;
		background: #1a1a1a;

		&:before {
			.StickToEdges;
			background-image: url("@{cloudUrlThemeNoAuto}blur-city.jpg");
			background-size: cover;
			background-position: center bottom;
			opacity: 0.1;
		}

		.top-wrapper {
			background: transparent;
		}

		.columns {
			margin-top: 0;
		}

		.columns__container {
			padding-top: 10px;
			min-height: 510px;
		}

		.columns__wrapper {
			background: transparent;
		}

		.toplinks__wrapper {
			border-top: 1px solid #a200ff;
			border-bottom: 1px solid #a200ff;
			margin-bottom: 25px;
		}
	}
}

.container {
	position: relative;

	@media @mobile {
		margin: 0 auto 0;
		padding: 0;
	}

	@media @tablet {
		width: 100%;
		padding: 0 10px;
		margin: 0 auto 0;
	}

	@media @desktop {
		width: 1170px;
		margin: 0 auto 0;
	}
}
/*#region TopLinksMobile*/
#TopLinksMobileSpacer {
	padding-top: 53px;
}

#TopLinksMobile {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: @mobileNavColor;
	color: white;
	z-index: 20;
	padding: 0;
	border-bottom: 1px solid white;
	.BodyFont();
	min-height: 5px;

	.navbar {
		margin-bottom: 0;
	}

	@media @tablet {
		display: none !important;
	}

	@media @sm {
		display: block !important;
	}

	#NavigationToggleText {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 14px 63px;
		text-align: right;
	}

	.navbar-toggle .icon-bar {
		background: white;
	}

	.row {
		padding-top: 10px;
	}

	.input-group {
		margin-bottom: 0;
	}
}

#MobileHomeLink {
	position: fixed;
	top: 10px;
	z-index: 10;
	background: transparent none repeat scroll 0% 0%;
	color: white;
	left: 10px;
	border-radius: 50%;
	border: 2px solid white;
}
/*#endregion TopLinksMobile*/
.top-wrapper {
	position: relative;
}

.header {
	@width: 1170px;
	@height: 198px;
	text-align: center;
	position: relative;

	@media @mobile {
		padding: 20px 10px;
	}

	@media @tablet {
		padding-top: round(percentage(@height/@width),2);
	}

	&__container {
		padding: 0;
	}

	&__wrapper {
		background: #000;
		position: relative;
	}

	&__logo {
		@media @mobile {
			display: block;
			margin: 10px auto 20px;
			text-align: center;
			max-width: 450px;
		}

		@media @tablet {
			position: absolute;
			width: 38.62%;
			bottom: 21.58%;
			left: 31.33%;
		}
	}

	&__call {
		.TopBarItem(224,@width,@height,auto,132,right,25);
		color: #00aaff;
		.MontserratBold;
		font-size: 1rem;
		line-height: 1;

		@media @tablet-only {
			font-size: 1.5vw;
			white-space: nowrap;
		}
	}

	&__phone {
		.TopBarItem(261,@width,@height,auto,85,right,9);
		font-size: 2.1875rem;
		color: #fff;
		line-height: 1;
		.MontserratLight;
		white-space: nowrap;

		&:hover {
			color: #fff;
		}

		@media @mobile {
			display: block;
			max-width: 280px !important;
		}

		@media @tablet-only {
			font-size: 3vw;
			white-space: nowrap;
		}
	}

	&__phone-2 {
		.TopBarItem(261,@width,@height,auto,85,right,9);
		font-size: 2.1875rem;
		color: #fff;
		line-height: 1;
		.MontserratLight;
		white-space: nowrap;
		display: none;

		&:hover {
			color: #fff;
		}

		@media @mobile {
			max-width: 280px !important;
		}

		@media @tablet-only {
			font-size: 3vw;
			white-space: nowrap;
		}
	}

	&__email {
		.TopBarItem(239,@width,@height,auto,59,right,12);
		color: #808080;
		.Oswald;
		font-size: 1.0635rem;
		line-height: 1;
		text-transform: uppercase;

		&:hover {
			color: #808080;
		}

		@media @mobile {
			display: block;
			max-width: 280px !important;
			margin-top: 10px;
			padding: 5px 0;
		}

		@media @tablet-only {
			font-size: 1.6vw;
			white-space: nowrap;
		}
	}

	&__follow {
		color: #00aaff;
		font-size: 1rem;
		.MontserratBold;

		@media @tablet-only {
			font-size: 1.5vw;
			white-space: nowrap;
		}

		@media @tablet {
			position: absolute;
			width: 17.44%;
			bottom: 57.67%;
			left: 2.14%;
		}
	}

	&__social-links {
		display: flex;
		justify-content: space-between;

		@media @mobile {
			margin: 20px auto 10px;
			max-width: 160px;
		}

		@media @tablet {
			position: absolute;
			width: 16%;
			bottom: 31%;
			left: 3%;
		}
	}

	&__social-link {
		@media @tablet {
			width: 21%;
		}

		@media @mobile {
			margin: 0 5px;
		}
	}

	.SocialMediaLinkFacebook img {
		padding-left: 100%;
		padding-top: 100%;
		background-image: url("@{cloudUrl}social/simplegreyround/facebook.png");
		background-size: 100%;
	}

	.SocialMediaLinkGoogle img {
		padding-left: 100%;
		padding-top: 100%;
		background-image: url("@{cloudUrl}social/simplegreyround/google.png");
		background-size: 100%;
	}

	.SocialMediaLinkInstagram img {
		padding-left: 100%;
		padding-top: 100%;
		background-image: url("@{cloudUrl}social/simplegreyround/instagram.png");
		background-size: 100%;
	}

	.SocialMediaLinkTwitter img {
		padding-left: 100%;
		padding-top: 100%;
		background-image: url("@{cloudUrl}social/simplegreyround/twitter.png");
		background-size: 100%;
	}

	.SocialMediaLinkYouTube img {
		padding-left: 100%;
		padding-top: 100%;
		background-image: url("@{cloudUrl}social/simplegreyround/youtube.png");
		background-size: 100%;
	}
}

.toplinks {
	position: relative;
	display: flex;
	justify-content: space-between;
	.border-top-radius(10px);
	padding: 0;
	flex-wrap: wrap;

	&__wrapper {
		background: #1a1a1a;
		position: relative;
	}

	&__container {
		padding: 0;
	}

	&__nav {
		flex-grow: 1;
		margin: 0 10px;
	}

	&__ul {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 0;
		padding-left: 0;
	}

	&__li {
		text-align: center;
		list-style: none;
		position: relative;
		.Transition3s();
		flex-grow: 1;
		padding: 0;

		&.open .dropdown-toggle:focus {
			color: #fff;
		}
	}

	&__link {
		color: #999;
		background: none;
		.transition(all 0.3s);
		white-space: nowrap;
		display: block;
		padding: 20px 5px;
		position: relative;
		z-index: 1;
		font-size: 1rem;
		.MontserratSemiBold;
		border-bottom: 3px solid transparent;
		text-transform: uppercase;

		&:hover, &.active, &:focus {
			color: #fff;
			border-bottom: 3px solid #a200ff;
		}

		&.active {
			.MontserratBold;
		}
	}

	&__dropdown {
		background: #1a1a1a;
		text-align: center;
		padding: 0;
	}

	&__dropdown-li {
		display: block;
		width: 100%;
		margin-left: 0;
		border-radius: 0;
	}

	&__dropdown-link {
		padding: 5px 10px !important;
		color: #fff !important;
		position: relative;
		z-index: 1;
		font-size: 1rem;
		transition: all 0.3s;
		.MontserratSemiBold;
		text-transform: uppercase;

		&:hover, &:focus, &.active {
			color: #a200ff !important;
			background-color: transparent !important;
		}

		&.active {
			.MontserratBold;
		}
	}
}

.banner {
	overflow: hidden;
	.BannerPanelSet(53.5%);
	position: relative;

	#CarouselContainer {
		margin: 0 auto;
	}

	.carousel-indicators {
		bottom: 22%;
	}
}

.featured-categories {

	&__inner {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		@media @mobile {
			background-color: #000;
			padding-top: 30px;
			padding-bottom: 22px;
		}

		@media @sm {
			margin-top: -5%;
		}

		@media @lg {
			margin-top: -130px;
		}
	}


	&__link {
		width: ~'calc(33.3333% - 30px)';
		margin: 0 15px 30px;

		@media @mobile {
			width: ~'calc(33.3333% - 8px)';
			margin: 0 4px 8px;
		}


		&:hover {
			.featured-categories__image-panel {
				box-shadow: 0 12px 7px -4px rgba(0,0,0,.2);
			}
		}
	}

	&__image-panel {
		padding-top: 67.57%;
		position: relative;
		transition: box-shadow 0.3s;
	}


	&__image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.no-gutter {
	margin-left: 0;
	margin-right: 0;

	& > [class*='col-'] {
		padding-right: 0;
		padding-left: 0;
	}
}

.columns {
	display: flex;
	border-radius: 15px;
	position: relative;
	z-index: 1;
	@columnsWidth: 1368px;
	@leftColWidth: 318px;
	@rightColWidth: 1050px;
	@padding: 54px;

	@media @mobile {
		margin-top: 0;
	}

	&__wrapper {
		position: relative;
		background: #fff;

		@media @tablet {
			padding-top: 1px;
		}
	}

	&__left {
		position: relative;
		width: round(percentage(@leftColWidth/@columnsWidth),2);
		margin-top: 15px;
	}

	&__container {
		padding-bottom: 40px;
		padding-top: 40px;
	}

	&__main {
		overflow: hidden;
		background: #fff;
		position: relative;
		background-size: 100% auto;
		background-position: 0 5px;
		width: 100%;
		padding: 20px 25px;

		@media @sm {
			width: round(percentage(@rightColWidth/@columnsWidth),2);
		}

		@media @mobile {
			padding: 0;
		}
	}

	&__main-offset {
		margin: 0 -25px;
		padding: 1px 25px;
		background: #fff;

		@media @mobile {
			padding: 1px 5px;
			margin: 0;
		}

		&:first-child {
			@media @mobile {
			}
		}
	}
}

.category-links {
	margin-bottom: 10px;
	margin-top: 20px;

	@media @tablet-only {
		margin-top: 21%;
	}

	&__link, &__child-link {
		position: relative;
		display: block;
		z-index: 1;
		overflow: hidden;
		margin: 10px 0;
		padding: 15px;
		text-align: left;
		line-height: 100%;
		color: #808080;
		.Transition3s();
		.Oswald;
		font-size: 1rem;
		border: 1px solid #e6e6e6;
		background: #fff;

		&:before {
			.StickToEdges;
			background: linear-gradient(to right, #bb5e9c 0%,#485ea8 51%,#489fd7 100%);
			opacity: 0;
			z-index: -1;
			transition: all 0.3s;
		}

		&:hover, &:focus, &.active {
			border: 1px solid transparent;
			color: #fff;

			&:before {
				opacity: 1;
			}
		}
	}

	&__child-link{
		margin-left:10px;
	}
}

.home-assets {
	&__wrapper {
		position: relative;
		background: #1a1a1a;
	}

	&__bg {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	@media @mobile {
		margin-left: -8px;
		margin-right: -8px;
		padding: 0 8px;

		& > div {
			padding-left: 8px;
			padding-right: 8px;
		}
	}

	@media (max-width:420px) {
		& > div {
			width: 100%;
		}
	}
}

#PageListings {
	@media @mobile {
		margin-left: -8px;
		margin-right: -8px;
		padding: 0 8px;

		& > div {
			padding-left: 8px;
			padding-right: 8px;
		}
	}

	@media (max-width:420px) {
		& > div {
			width: 100%;
		}
	}
}
/*#region Castle Panels*/
.castlePanel {
	width: 100%;
	padding: 5px 5px 10px;
	border-radius: 5px;
	margin: 0 0 30px;
	text-align: center;
	transition: all 0.3s;
	position: relative;
	box-shadow: 1px 1px 2px rgba(0,0,0,0.3);
	display: flex;
	flex-direction: column;
	border: 1px solid #ccc;

	@media @mobile {
		text-align: center;
	}

	&:hover {
		box-shadow: 5px 5px 6px 0 rgba(0,0,0,.075);

		.castleCheckBook {
			&:before {
				opacity: 1;
			}
		}
	}

	.SingleImagePanel {
		background: transparent;
		order: 1;
		margin: 10px 0 0;
		position: relative;
		height: auto;
		padding-top: 0;

		&:before {
			content: "";
			width: 100%;
			padding-top: 85.83%;
			display: block;
		}

		img {
			top: 0 !important;
			left:0 !important;
			width:100%;
			height: 100%; 
			object-fit:cover;
		}
	}

	.castleTitlePanel {
		margin-top: 10px;
		padding: 0 5%;
		display: flex;
		justify-content: center;
		align-items: center;
		order: 2;

		a {
			color: #2c3437;
			font-size: 1rem;
			.MontserratBold;
			position: relative;
		}
	}

	.castleSubtitle {
		.OpenSansSemiBold;
		font-size: 0.875rem;
		color: #a1aeb2;
		order: 3;
	}

	.castlePriceDefault, .castlePriceDiscounted {
		color: #000;
		position: relative;
		order: 4;
		padding: 0 5%;
		margin-top: 10px;
		.OpenSansBold;
		font-size: 1rem;

		span.oldprice {
			font-size: 0.8rem;
			color: #df1f1f;
		}

		span.newprice {
			font-size: 1rem;
		}
	}

	.castlePriceDefault {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.castleCheckBook {
		order: 5;
		background: linear-gradient(to right, #bb5e9c 0%,#485ea8 51%,#489fd7 100%);
		border-radius: 5px;
		position: relative;
		z-index: 1;
		height: auto;
		padding: 10px;
		margin-top: 10px;
		text-transform: uppercase;
		.OpenSansBold;
		display: block;
		font-size: 1rem;
		margin-left: 5px;
		margin-right: 5px;

		&:before {
			.StickToEdges;
			background: linear-gradient(to right, #bb5e9c 0%,#485ea8 51%,#489fd7 100%);
			transform: scaleX(-1);
			transition: all 0.3s;
			border-radius: 5px;
			z-index: -1;
			opacity: 0;
		}
	}
}
/*#endregion Castle Panels*/
.DetailsTitle h1, .DetailsTitle h2, .DetailsMainImage {
	background-color: @castleDetailsColor;
}

.BookButtons {
	text-align: center;

	.BookButton, .CheckButton {
		width: auto;
		display: inline-block;
		margin: 20px 10px 10px;
		float: none;
		width: 195px;
	}
}

.DetailsLeft {
	background-image: none;
	padding-bottom: 0;
}

a.BackToCategory {
	background: #bb5e9c; /* Old browsers */
	background: -moz-linear-gradient(left, #bb5e9c 0%, #485ea8 51%, #489fd7 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left, #bb5e9c 0%,#485ea8 51%,#489fd7 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, #bb5e9c 0%,#485ea8 51%,#489fd7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bb5e9c', endColorstr='#489fd7',GradientType=1 ); /* IE6-9 */
	text-shadow: -1px -1px 1px rgba(0,0,0,0.3);

	&:hover {
		background: #489fd7; /* Old browsers */
		background: -moz-linear-gradient(left, #489fd7 0%, #485ea8 51%, #bb5e9c 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left, #489fd7 0%,#485ea8 51%,#bb5e9c 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right, #489fd7 0%,#485ea8 51%,#bb5e9c 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bb5e9c', endColorstr='#489fd7',GradientType=1 ); /* IE6-9 */
		text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
	}
}

.testimonials {
	text-align: center;
	position: relative;
	overflow: hidden;

	&__wrapper {
		position: relative;
		background: #292929;
	}

	&__wrapper-overlay {
		position: absolute;
		top: 0;
		right:0;
		bottom:0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__link {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		background-color: #fff;
		margin: 0 6%;
		padding: 30px 15px;
		border-radius: 5px;
	}

	.slick-slide {
		opacity: 0.7;
		margin: 0 -27px;
		transform: scale(0.9);
		transition: all 1s;

		&.slick-center {
			opacity: 1;
			transform: scale(1);
		}
	}

	&__text {
		color: #808080;
		margin-bottom: 20px;
		font-size: 1rem;
	}

	&__title {
		color: #fff;
		margin-top: 0;
	}

	&__author {
		color: #4b3ade;
		padding: 10px;
		margin-bottom: 10px;
		background-size: 100% 100%;
		position: relative;
		font-size: 1.25rem;
		.OswaldMed;
	}
}

.footer {
	&__wrapper {
		background: #000;
		color: #fff;
		padding: 25px 10px;
		width: 100%;
		z-index: 1;
		position: relative;

		@media @mobile {
			text-align: center;
		}
	}

	.flex-row {
		@media @sm {
			display: flex;
			align-items: center;
		}
	}

	&__links {
		text-align: center;

		@media @mobile {
			text-align: center;
			margin-top: 15px;
		}

		a {
			color: #b3b3b3;
			margin-right: 10px;

			@media @mobile{
				padding:10px;
				margin:0 5px 10px;
				display:inline-block;
			}
		}
	}

	p {
		color: #b3b3b3;
	}

	&__BCN {
		display: block;
		max-width: 180px;

		@media @mobile {
			margin-top: 10px;
			text-align: center;
			display: inline-block;
		}

		@media @sm {
			float: right;
		}

		a {
			color: #a1a1a1;

			&:hover {
				color: #fff;
			}
		}
	}
}

.photo-gallery {
	@color: #1c2430;
	position: relative;
	background: @color;
	background-image: url("@{cloudUrlTheme}gallery-feed-bg.png");

	&:before, &:after {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		width: 80px;

		@media @tablet {
			width: 100px;
		}

		@media @desktop {
			width: 200px;
		}
	}

	&:before {
		left: 0;
		.HorizontalGradient(fade(@color,100), fade(@color,0));
		z-index: 1;
	}

	&:after {
		right: 0;
		.HorizontalGradient( fade(@color,0),fade(@color,100));
	}

	&__title {
		margin: 25px auto;
		display: block;
		padding: 40px 0 0;

		@media @tablet {
		}

		@media @desktop {
		}
	}

	&__slide {
		margin: 0 10px;
	}

	.thumbnail {
		background-color: transparent;
		border: none;
	}

	.DetailsThumbnail.thumbnail img {
		border: 3px solid #fff;
	}
}

#BouncyCastleHireLink, .bch-link {
	color: #a1a1a1;
	text-align: center;
}
/*#region Animations*/
.wow {
	visibility: hidden;
}

.animated {
	animation-duration: 1s;
	animation-fill-mode: both
}

@keyframes fadeIn {
	0% {
		opacity: 0
	}

	to {
		opacity: 1
	}
}

.fadeIn {
	animation-name: fadeIn
}

@keyframes scale {
	to {
		transform: scale(1.05);
	}
}

.fadeIn2 {
	animation: fadeIn2 3.5s 0.5s 1 both;
}

@keyframes fadeIn2 {
	to {
		mask-position: center top;
	}
}
/*#endregion Animations*/
@keyframes resize {
	50% {
		transform: scale(1.02,0.98);
	}
}
