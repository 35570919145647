/*#region paddingTop*/

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-15 {
    padding-top: 15px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-25 {
    padding-top: 25px;
}

.padding-top-35 {
    padding-top: 35px;
}

.padding-top-40 {
    padding-top: 40px;
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-top-60 {
    padding-top: 60px;
}

.padding-top-80 {
    padding-top: 80px;
}

.padding-top-100 {
    padding-top: 100px;
}

/*#endregion paddingTop*/

/*#region paddingBottom*/
.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-15 {
    padding-bottom: 15px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-bottom-25 {
    padding-bottom: 25px;
}

.padding-bottom-30 {
    padding-bottom: 30px;
}

.padding-bottom-35 {
    padding-bottom: 35px;
}

.padding-bottom-40 {
    padding-bottom: 40px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.padding-bottom-60 {
    padding-bottom: 60px;
}

.padding-bottom-80 {
    padding-bottom: 80px;
}

.padding-bottom-100 {
    padding-bottom: 100px;
}
/*#endregion paddingBottom*/

/*#region paddingY*/

.padding-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.padding-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.padding-y-30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.padding-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.padding-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.padding-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.padding-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.padding-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.padding-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

/*#endregion paddingY*/

/*#region paddingX*/

.padding-x-10p {
    padding: 0 10%;
}

.padding-x-10 {
    padding: 0 10px;
}

.padding-x-15 {
    padding: 0 15px;
}

.padding-x-20 {
    padding: 0 20px;
}



/*#endregion paddingX*/

/*#region marginTop*/

.margin-top-0 {
    margin-top: 0;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}


.margin-top-25 {
    margin-top: 25px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-35 {
    margin-top: 35px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-60 {
    margin-top: 60px;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-100 {
    margin-top: 100px;
}

/*#endregion marginTop*/

/*#region marginBottom*/
.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}
.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-35 {
    margin-bottom: 35px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-60 {
    margin-bottom: 60px;
}

.margin-bottom-80 {
    margin-bottom: 80px;
}

.margin-bottom-100 {
    margin-bottom: 100px;
}
/*#endregion marginBottom*/

/*#region marginY*/

.margin-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.margin-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.margin-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.margin-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}


.margin-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.margin-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.margin-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.margin-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.margin-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.margin-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
}

/*#endregion marginY*/

.white {
    color: #FFF;
}

.black {
    color: #000;
}

.container-bg-white {
    background-color: #fff;
}

.uppercase {
    text-transform: uppercase;
}

.relative {
    position: relative;
}

.display-inline-block {
    display: inline-block;
}

.display-block {
    display: block;
}

img.HoverBounce,
.HoverBounce img {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    display: block;
    overflow: hidden;
}

    img.HoverBounce :hover,
    .HoverBounce img :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

a.HoverBounce {
    display: block;
}

    a.HoverBounce img {
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        -webkit-transition: -webkit-transform 0.5s ease-in-out;
        transition: -webkit-transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
        display: block;
        overflow: hidden;
    }

    a.HoverBounce :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

/*#region Fonts*/
.OpenSans,
.BodyFont {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
.OpenSansSemiBold {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600 !important;
}
.OpenSansBold {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700 !important;
}
.Montserrat {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400 !important;
}
.MontserratLight {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300 !important;
}
.MontserratSemiBold {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600 !important;
}
.MontserratBold {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
}
.Oswald {
  font-family: 'Oswald', sans-serif;
  font-weight: 400 !important;
}
.OswaldMed {
  font-family: 'Oswald', sans-serif;
  font-weight: 500 !important;
}
html {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
}
body {
  overflow-x: hidden;
  background: #000;
}
.textContent {
  font-size: 1rem;
}
@media (max-width: 767px) {
  .textContent {
    text-align: center;
  }
}
.textContent h1,
.lower-content h2 {
  font-family: 'Oswald', sans-serif;
  font-weight: 400 !important;
  color: #4b3ade;
  text-transform: uppercase;
  font-size: 2.5rem;
  margin-top: 0;
}
.lower-content {
  padding: 25px 20px 15px;
  background: #fff;
}
.section-title--h2 {
  font-family: 'Oswald', sans-serif;
  font-weight: 400 !important;
  color: #fff;
  font-size: 2.5rem;
}
.section-title--h3 {
  font-size: 1.875rem;
}
/*#endregion Fonts*/
@media (max-width: 767px) {
  #BodyContent {
    padding-top: 0;
  }
}
form {
  margin: 0 auto;
  position: relative;
}
.outer-wrapper {
  max-width: 100vw;
  position: relative;
}
@media (max-width: 767px) {
  .outer-wrapper {
    overflow: hidden;
  }
}
.outer-wrapper.outer-wrapper--alt {
  position: relative;
  background: #1a1a1a;
}
.outer-wrapper.outer-wrapper--alt:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/014/blur-city.jpg");
  background-size: cover;
  background-position: center bottom;
  opacity: 0.1;
}
.outer-wrapper.outer-wrapper--alt .top-wrapper {
  background: transparent;
}
.outer-wrapper.outer-wrapper--alt .columns {
  margin-top: 0;
}
.outer-wrapper.outer-wrapper--alt .columns__container {
  padding-top: 10px;
  min-height: 510px;
}
.outer-wrapper.outer-wrapper--alt .columns__wrapper {
  background: transparent;
}
.outer-wrapper.outer-wrapper--alt .toplinks__wrapper {
  border-top: 1px solid #a200ff;
  border-bottom: 1px solid #a200ff;
  margin-bottom: 25px;
}
.container {
  position: relative;
}
@media (max-width: 767px) {
  .container {
    margin: 0 auto 0;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .container {
    width: 100%;
    padding: 0 10px;
    margin: 0 auto 0;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
    margin: 0 auto 0;
  }
}
/*#region TopLinksMobile*/
#TopLinksMobileSpacer {
  padding-top: 53px;
}
#TopLinksMobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #1a1a1a;
  color: white;
  z-index: 20;
  padding: 0;
  border-bottom: 1px solid white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  min-height: 5px;
}
#TopLinksMobile .navbar {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: none !important;
  }
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: block !important;
  }
}
#TopLinksMobile #NavigationToggleText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 14px 63px;
  text-align: right;
}
#TopLinksMobile .navbar-toggle .icon-bar {
  background: white;
}
#TopLinksMobile .row {
  padding-top: 10px;
}
#TopLinksMobile .input-group {
  margin-bottom: 0;
}
#MobileHomeLink {
  position: fixed;
  top: 10px;
  z-index: 10;
  background: transparent none repeat scroll 0% 0%;
  color: white;
  left: 10px;
  border-radius: 50%;
  border: 2px solid white;
}
/*#endregion TopLinksMobile*/
.top-wrapper {
  position: relative;
}
.header {
  text-align: center;
  position: relative;
}
@media (max-width: 767px) {
  .header {
    padding: 20px 10px;
  }
}
@media (min-width: 768px) {
  .header {
    padding-top: 16.92%;
  }
}
.header__container {
  padding: 0;
}
.header__wrapper {
  background: #000;
  position: relative;
}
@media (max-width: 767px) {
  .header__logo {
    display: block;
    margin: 10px auto 20px;
    text-align: center;
    max-width: 450px;
  }
}
@media (min-width: 768px) {
  .header__logo {
    position: absolute;
    width: 38.62%;
    bottom: 21.58%;
    left: 31.33%;
  }
}
.header__call {
  color: #00aaff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
  font-size: 1rem;
  line-height: 1;
}
@media (max-width: 767px) {
  .header__call {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__call {
    position: absolute;
    width: 19.15%;
    bottom: 66.67%;
    right: 2.14%;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .header__call {
    font-size: 1.5vw;
    white-space: nowrap;
  }
}
.header__phone {
  font-size: 2.1875rem;
  color: #fff;
  line-height: 1;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300 !important;
  white-space: nowrap;
}
@media (max-width: 767px) {
  .header__phone {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__phone {
    position: absolute;
    width: 22.31%;
    bottom: 42.93%;
    right: 0.77%;
  }
}
.header__phone:hover {
  color: #fff;
}
@media (max-width: 767px) {
  .header__phone {
    display: block;
    max-width: 280px !important;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .header__phone {
    font-size: 3vw;
    white-space: nowrap;
  }
}
.header__phone-2 {
  font-size: 2.1875rem;
  color: #fff;
  line-height: 1;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300 !important;
  white-space: nowrap;
  display: none;
}
@media (max-width: 767px) {
  .header__phone-2 {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__phone-2 {
    position: absolute;
    width: 22.31%;
    bottom: 42.93%;
    right: 0.77%;
  }
}
.header__phone-2:hover {
  color: #fff;
}
@media (max-width: 767px) {
  .header__phone-2 {
    max-width: 280px !important;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .header__phone-2 {
    font-size: 3vw;
    white-space: nowrap;
  }
}
.header__email {
  color: #808080;
  font-family: 'Oswald', sans-serif;
  font-weight: 400 !important;
  font-size: 1.0635rem;
  line-height: 1;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .header__email {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__email {
    position: absolute;
    width: 20.43%;
    bottom: 29.8%;
    right: 1.03%;
  }
}
.header__email:hover {
  color: #808080;
}
@media (max-width: 767px) {
  .header__email {
    display: block;
    max-width: 280px !important;
    margin-top: 10px;
    padding: 5px 0;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .header__email {
    font-size: 1.6vw;
    white-space: nowrap;
  }
}
.header__follow {
  color: #00aaff;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .header__follow {
    font-size: 1.5vw;
    white-space: nowrap;
  }
}
@media (min-width: 768px) {
  .header__follow {
    position: absolute;
    width: 17.44%;
    bottom: 57.67%;
    left: 2.14%;
  }
}
.header__social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 767px) {
  .header__social-links {
    margin: 20px auto 10px;
    max-width: 160px;
  }
}
@media (min-width: 768px) {
  .header__social-links {
    position: absolute;
    width: 16%;
    bottom: 31%;
    left: 3%;
  }
}
@media (min-width: 768px) {
  .header__social-link {
    width: 21%;
  }
}
@media (max-width: 767px) {
  .header__social-link {
    margin: 0 5px;
  }
}
.header .SocialMediaLinkFacebook img {
  padding-left: 100%;
  padding-top: 100%;
  background-image: url("https://files.bookingonline.co.uk/image/upload/social/simplegreyround/facebook.png");
  background-size: 100%;
}
.header .SocialMediaLinkGoogle img {
  padding-left: 100%;
  padding-top: 100%;
  background-image: url("https://files.bookingonline.co.uk/image/upload/social/simplegreyround/google.png");
  background-size: 100%;
}
.header .SocialMediaLinkInstagram img {
  padding-left: 100%;
  padding-top: 100%;
  background-image: url("https://files.bookingonline.co.uk/image/upload/social/simplegreyround/instagram.png");
  background-size: 100%;
}
.header .SocialMediaLinkTwitter img {
  padding-left: 100%;
  padding-top: 100%;
  background-image: url("https://files.bookingonline.co.uk/image/upload/social/simplegreyround/twitter.png");
  background-size: 100%;
}
.header .SocialMediaLinkYouTube img {
  padding-left: 100%;
  padding-top: 100%;
  background-image: url("https://files.bookingonline.co.uk/image/upload/social/simplegreyround/youtube.png");
  background-size: 100%;
}
.toplinks {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 0;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.toplinks__wrapper {
  background: #1a1a1a;
  position: relative;
}
.toplinks__container {
  padding: 0;
}
.toplinks__nav {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin: 0 10px;
}
.toplinks__ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
}
.toplinks__li {
  text-align: center;
  list-style: none;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  padding: 0;
}
.toplinks__li.open .dropdown-toggle:focus {
  color: #fff;
}
.toplinks__link {
  color: #999;
  background: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  white-space: nowrap;
  display: block;
  padding: 20px 5px;
  position: relative;
  z-index: 1;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600 !important;
  border-bottom: 3px solid transparent;
  text-transform: uppercase;
}
.toplinks__link:hover,
.toplinks__link.active,
.toplinks__link:focus {
  color: #fff;
  border-bottom: 3px solid #a200ff;
}
.toplinks__link.active {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
}
.toplinks__dropdown {
  background: #1a1a1a;
  text-align: center;
  padding: 0;
}
.toplinks__dropdown-li {
  display: block;
  width: 100%;
  margin-left: 0;
  border-radius: 0;
}
.toplinks__dropdown-link {
  padding: 5px 10px !important;
  color: #fff !important;
  position: relative;
  z-index: 1;
  font-size: 1rem;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600 !important;
  text-transform: uppercase;
}
.toplinks__dropdown-link:hover,
.toplinks__dropdown-link:focus,
.toplinks__dropdown-link.active {
  color: #a200ff !important;
  background-color: transparent !important;
}
.toplinks__dropdown-link.active {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
}
.banner {
  overflow: hidden;
  position: relative;
}
.banner #CarouselContainer {
  padding-top: 53.5%;
  overflow: hidden;
}
.banner #CarouselContainer .carousel-inner {
  position: absolute;
  top: 0;
  height: 100%;
}
.banner #CarouselContainer .carousel-inner .item {
  width: 100%;
}
.banner #CarouselContainer .carousel-inner .item a img,
.banner #CarouselContainer .carousel-inner .item img {
  width: 100%;
}
.banner #CarouselContainer {
  margin: 0 auto;
}
.banner .carousel-indicators {
  bottom: 22%;
}
.featured-categories__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 767px) {
  .featured-categories__inner {
    background-color: #000;
    padding-top: 30px;
    padding-bottom: 22px;
  }
}
@media (min-width: 768px) {
  .featured-categories__inner {
    margin-top: -5%;
  }
}
@media (min-width: 1200px) {
  .featured-categories__inner {
    margin-top: -130px;
  }
}
.featured-categories__link {
  width: calc(33.3333% - 30px);
  margin: 0 15px 30px;
}
@media (max-width: 767px) {
  .featured-categories__link {
    width: calc(33.3333% - 8px);
    margin: 0 4px 8px;
  }
}
.featured-categories__link:hover .featured-categories__image-panel {
  -webkit-box-shadow: 0 12px 7px -4px rgba(0, 0, 0, 0.2);
          box-shadow: 0 12px 7px -4px rgba(0, 0, 0, 0.2);
}
.featured-categories__image-panel {
  padding-top: 67.57%;
  position: relative;
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}
.featured-categories__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.no-gutter {
  margin-left: 0;
  margin-right: 0;
}
.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}
.columns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 15px;
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  .columns {
    margin-top: 0;
  }
}
.columns__wrapper {
  position: relative;
  background: #fff;
}
@media (min-width: 768px) {
  .columns__wrapper {
    padding-top: 1px;
  }
}
.columns__left {
  position: relative;
  width: 23.25%;
  margin-top: 15px;
}
.columns__container {
  padding-bottom: 40px;
  padding-top: 40px;
}
.columns__main {
  overflow: hidden;
  background: #fff;
  position: relative;
  background-size: 100% auto;
  background-position: 0 5px;
  width: 100%;
  padding: 20px 25px;
}
@media (min-width: 768px) {
  .columns__main {
    width: 76.75%;
  }
}
@media (max-width: 767px) {
  .columns__main {
    padding: 0;
  }
}
.columns__main-offset {
  margin: 0 -25px;
  padding: 1px 25px;
  background: #fff;
}
@media (max-width: 767px) {
  .columns__main-offset {
    padding: 1px 5px;
    margin: 0;
  }
}
.category-links {
  margin-bottom: 10px;
  margin-top: 20px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .category-links {
    margin-top: 21%;
  }
}
.category-links__link,
.category-links__child-link {
  position: relative;
  display: block;
  z-index: 1;
  overflow: hidden;
  margin: 10px 0;
  padding: 15px;
  text-align: left;
  line-height: 100%;
  color: #808080;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-family: 'Oswald', sans-serif;
  font-weight: 400 !important;
  font-size: 1rem;
  border: 1px solid #e6e6e6;
  background: #fff;
}
.category-links__link:before,
.category-links__child-link:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background: -webkit-gradient(linear, left top, right top, from(#bb5e9c), color-stop(51%, #485ea8), to(#489fd7));
  background: linear-gradient(to right, #bb5e9c 0%, #485ea8 51%, #489fd7 100%);
  opacity: 0;
  z-index: -1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.category-links__link:hover,
.category-links__child-link:hover,
.category-links__link:focus,
.category-links__child-link:focus,
.category-links__link.active,
.category-links__child-link.active {
  border: 1px solid transparent;
  color: #fff;
}
.category-links__link:hover:before,
.category-links__child-link:hover:before,
.category-links__link:focus:before,
.category-links__child-link:focus:before,
.category-links__link.active:before,
.category-links__child-link.active:before {
  opacity: 1;
}
.category-links__child-link {
  margin-left: 10px;
}
.home-assets__wrapper {
  position: relative;
  background: #1a1a1a;
}
.home-assets__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 767px) {
  .home-assets {
    margin-left: -8px;
    margin-right: -8px;
    padding: 0 8px;
  }
  .home-assets > div {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media (max-width: 420px) {
  .home-assets > div {
    width: 100%;
  }
}
@media (max-width: 767px) {
  #PageListings {
    margin-left: -8px;
    margin-right: -8px;
    padding: 0 8px;
  }
  #PageListings > div {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media (max-width: 420px) {
  #PageListings > div {
    width: 100%;
  }
}
/*#region Castle Panels*/
.castlePanel {
  width: 100%;
  padding: 5px 5px 10px;
  border-radius: 5px;
  margin: 0 0 30px;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
          box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: 1px solid #ccc;
}
@media (max-width: 767px) {
  .castlePanel {
    text-align: center;
  }
}
.castlePanel:hover {
  -webkit-box-shadow: 5px 5px 6px 0 rgba(0, 0, 0, 0.075);
          box-shadow: 5px 5px 6px 0 rgba(0, 0, 0, 0.075);
}
.castlePanel:hover .castleCheckBook:before {
  opacity: 1;
}
.castlePanel .SingleImagePanel {
  background: transparent;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  margin: 10px 0 0;
  position: relative;
  height: auto;
  padding-top: 0;
}
.castlePanel .SingleImagePanel:before {
  content: "";
  width: 100%;
  padding-top: 85.83%;
  display: block;
}
.castlePanel .SingleImagePanel img {
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.castlePanel .castleTitlePanel {
  margin-top: 10px;
  padding: 0 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}
.castlePanel .castleTitlePanel a {
  color: #2c3437;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
  position: relative;
}
.castlePanel .castleSubtitle {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600 !important;
  font-size: 0.875rem;
  color: #a1aeb2;
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
}
.castlePanel .castlePriceDefault,
.castlePanel .castlePriceDiscounted {
  color: #000;
  position: relative;
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4;
  padding: 0 5%;
  margin-top: 10px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700 !important;
  font-size: 1rem;
}
.castlePanel .castlePriceDefault span.oldprice,
.castlePanel .castlePriceDiscounted span.oldprice {
  font-size: 0.8rem;
  color: #df1f1f;
}
.castlePanel .castlePriceDefault span.newprice,
.castlePanel .castlePriceDiscounted span.newprice {
  font-size: 1rem;
}
.castlePanel .castlePriceDefault {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.castlePanel .castleCheckBook {
  -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
          order: 5;
  background: -webkit-gradient(linear, left top, right top, from(#bb5e9c), color-stop(51%, #485ea8), to(#489fd7));
  background: linear-gradient(to right, #bb5e9c 0%, #485ea8 51%, #489fd7 100%);
  border-radius: 5px;
  position: relative;
  z-index: 1;
  height: auto;
  padding: 10px;
  margin-top: 10px;
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700 !important;
  display: block;
  font-size: 1rem;
  margin-left: 5px;
  margin-right: 5px;
}
.castlePanel .castleCheckBook:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background: -webkit-gradient(linear, left top, right top, from(#bb5e9c), color-stop(51%, #485ea8), to(#489fd7));
  background: linear-gradient(to right, #bb5e9c 0%, #485ea8 51%, #489fd7 100%);
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 5px;
  z-index: -1;
  opacity: 0;
}
/*#endregion Castle Panels*/
.DetailsTitle h1,
.DetailsTitle h2,
.DetailsMainImage {
  background-color: #a200ff;
}
.BookButtons {
  text-align: center;
}
.BookButtons .BookButton,
.BookButtons .CheckButton {
  width: auto;
  display: inline-block;
  margin: 20px 10px 10px;
  float: none;
  width: 195px;
}
.DetailsLeft {
  background-image: none;
  padding-bottom: 0;
}
a.BackToCategory {
  background: #bb5e9c;
  /* Old browsers */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, right top, from(#bb5e9c), color-stop(51%, #485ea8), to(#489fd7));
  background: linear-gradient(to right, #bb5e9c 0%, #485ea8 51%, #489fd7 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#bb5e9c', endColorstr='#489fd7', GradientType=1);
  /* IE6-9 */
  text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.3);
}
a.BackToCategory:hover {
  background: #489fd7;
  /* Old browsers */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, right top, from(#489fd7), color-stop(51%, #485ea8), to(#bb5e9c));
  background: linear-gradient(to right, #489fd7 0%, #485ea8 51%, #bb5e9c 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#bb5e9c', endColorstr='#489fd7', GradientType=1);
  /* IE6-9 */
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}
.testimonials {
  text-align: center;
  position: relative;
  overflow: hidden;
}
.testimonials__wrapper {
  position: relative;
  background: #292929;
}
.testimonials__wrapper-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.testimonials__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #fff;
  margin: 0 6%;
  padding: 30px 15px;
  border-radius: 5px;
}
.testimonials .slick-slide {
  opacity: 0.7;
  margin: 0 -27px;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  -webkit-transition: all 1s;
  transition: all 1s;
}
.testimonials .slick-slide.slick-center {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.testimonials__text {
  color: #808080;
  margin-bottom: 20px;
  font-size: 1rem;
}
.testimonials__title {
  color: #fff;
  margin-top: 0;
}
.testimonials__author {
  color: #4b3ade;
  padding: 10px;
  margin-bottom: 10px;
  background-size: 100% 100%;
  position: relative;
  font-size: 1.25rem;
  font-family: 'Oswald', sans-serif;
  font-weight: 500 !important;
}
.footer__wrapper {
  background: #000;
  color: #fff;
  padding: 25px 10px;
  width: 100%;
  z-index: 1;
  position: relative;
}
@media (max-width: 767px) {
  .footer__wrapper {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .footer .flex-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.footer__links {
  text-align: center;
}
@media (max-width: 767px) {
  .footer__links {
    text-align: center;
    margin-top: 15px;
  }
}
.footer__links a {
  color: #b3b3b3;
  margin-right: 10px;
}
@media (max-width: 767px) {
  .footer__links a {
    padding: 10px;
    margin: 0 5px 10px;
    display: inline-block;
  }
}
.footer p {
  color: #b3b3b3;
}
.footer__BCN {
  display: block;
  max-width: 180px;
}
@media (max-width: 767px) {
  .footer__BCN {
    margin-top: 10px;
    text-align: center;
    display: inline-block;
  }
}
@media (min-width: 768px) {
  .footer__BCN {
    float: right;
  }
}
.footer__BCN a {
  color: #a1a1a1;
}
.footer__BCN a:hover {
  color: #fff;
}
.photo-gallery {
  position: relative;
  background: #1c2430;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/014/gallery-feed-bg.png");
}
.photo-gallery:before,
.photo-gallery:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 80px;
}
@media (min-width: 768px) {
  .photo-gallery:before,
  .photo-gallery:after {
    width: 100px;
  }
}
@media (min-width: 1200px) {
  .photo-gallery:before,
  .photo-gallery:after {
    width: 200px;
  }
}
.photo-gallery:before {
  left: 0;
  background-color: #1c2430;
  background-image: -webkit-linear-gradient(left, #1c2430, rgba(28, 36, 48, 0));
  background: -webkit-gradient(linear, left top, right top, from(#1c2430), to(rgba(28, 36, 48, 0)));
  background: linear-gradient(to right, #1c2430, rgba(28, 36, 48, 0));
  z-index: 1;
}
.photo-gallery:after {
  right: 0;
  background-color: rgba(28, 36, 48, 0);
  background-image: -webkit-linear-gradient(left, rgba(28, 36, 48, 0), #1c2430);
  background: -webkit-gradient(linear, left top, right top, from(rgba(28, 36, 48, 0)), to(#1c2430));
  background: linear-gradient(to right, rgba(28, 36, 48, 0), #1c2430);
}
.photo-gallery__title {
  margin: 25px auto;
  display: block;
  padding: 40px 0 0;
}
.photo-gallery__slide {
  margin: 0 10px;
}
.photo-gallery .thumbnail {
  background-color: transparent;
  border: none;
}
.photo-gallery .DetailsThumbnail.thumbnail img {
  border: 3px solid #fff;
}
#BouncyCastleHireLink,
.bch-link {
  color: #a1a1a1;
  text-align: center;
}
/*#region Animations*/
.wow {
  visibility: hidden;
}
.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
}
@-webkit-keyframes scale {
  to {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
}
@keyframes scale {
  to {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
}
.fadeIn2 {
  -webkit-animation: fadeIn2 3.5s 0.5s 1 both;
          animation: fadeIn2 3.5s 0.5s 1 both;
}
@-webkit-keyframes fadeIn2 {
  to {
    -webkit-mask-position: center top;
            mask-position: center top;
  }
}
@keyframes fadeIn2 {
  to {
    -webkit-mask-position: center top;
            mask-position: center top;
  }
}
/*#endregion Animations*/
@-webkit-keyframes resize {
  50% {
    -webkit-transform: scale(1.02, 0.98);
            transform: scale(1.02, 0.98);
  }
}
@keyframes resize {
  50% {
    -webkit-transform: scale(1.02, 0.98);
            transform: scale(1.02, 0.98);
  }
}
